<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Utilisateurs</h4>
        <Dialog
          v-model:visible="dialogOfUpdateUser"
          :style="{ width: '900px' }"
          header="Modifier les Permisions"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12">
                <h5>Permisions</h5>
                <small class="p-error" v-if="errorsAdd['permissions']">
                  {{ errorsAdd["permissions"] }}
                </small>
                <div class="grid">
                  <div
                    style="margin-top: 5px"
                    class="col-4 md:col-3"
                    v-for="permision in allPermissions"
                    :key="permision"
                  >
                    <div class="field-checkbox mb-0">
                      <Checkbox
                        id="checkOption1"
                        name="option"
                        :value="permision.id"
                        v-model="permissionsValue"
                      />
                      <label for="checkOption1">{{ permision.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfUpdate = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormupP"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfShow"
          :style="{ width: '450px' }"
          header="Détails du Utilisateur"
          :modal="true"
          class="p-fluid"
        >
          Nom : {{ clientToShow.nom }}
          <Divider />
          Prénom :{{ clientToShow.prenom }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Type :{{ clientToShow.name }}
          <Divider />
          Téléphone :{{ clientToShow.tele }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Gsm :{{ clientToShow.gsm }}
          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfShow = false"
            />
          </template>
        </Dialog>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un nouveau Utilisateur"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showAddCLient()"
              />
            </div>
          </template>
        </Toolbar>
        <DataTable
          :value="clients"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'nom',
            'prenom',
            'email',
            'type',
            'telephone',
            'gsm',
          ]"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun Utilisateur trouvé. </template>
          <template #loading>
            Chargement des données Utilisateurs. Veuillez patienter.
          </template>
          <Column
            field="nom"
            header="Nom"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nom }}
            </template>
          </Column>
          <Column
            field="prenom"
            header="Prénom"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.prenom }}
            </template>
          </Column>
          <Column
            field="email"
            header="Email"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column
            field="type"
            header="Type "
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column
            field="telephone"
            header="Téléphone"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.tele }}
            </template>
          </Column>
          <Column
            field="gsm"
            header="Gsm"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.gsm }}
            </template>
          </Column>
          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <Button
                  @click="showclientById(data.data)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                />
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="updateClient(data.data.id)"
                />
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-sitemap"
                  class="p-button-raised p-button-rounded p-button-secondary"
                  @click="updatePermission(data.data)"
                />
                <Button
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteclient(data.data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter un Utilisateur"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Non </label>

                <InputText
                  v-model="clientToAdd.nom"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nom'] }"
                />
                <small class="p-error" v-if="errorsAdd['nom']">
                  {{ errorsAdd["nom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Prénom </label>
                <InputText
                  v-model="clientToAdd.prenom"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['prenom'] }"
                />
                <small class="p-error" v-if="errorsAdd['prenom']">
                  {{ errorsAdd["prenom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Téléphone </label>
                <InputText
                  v-model="clientToAdd.tele"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['tele'] }"
                />
                <small class="p-error" v-if="errorsAdd['tele']">
                  {{ errorsAdd["tele"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="zip">GSM</label>
                <InputText
                  v-model="clientToAdd.gsm"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['gsm'] }"
                />
                <small class="p-error" v-if="errorsAdd['gsm']">
                  {{ errorsAdd["gsm"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Role </label>
                <Dropdown
                  :class="{ 'is-invalid': errorsAdd['role_id'] }"
                  v-model="role_id"
                  :options="roles"
                  optionLabel="name"
                  placeholder="sélectionner Role"
                  :filter="true"
                ></Dropdown>
                <small class="p-error" v-if="errorsAdd['role_id']">
                  {{ errorsAdd["role_id"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="city">Plafond </label>
                <InputNumber
                  v-model="clientToAdd.plafondUser"
                  mode="currency"
                  currency="MAD"
                  :class="{ 'is-invalid': errorsAdd['plafondUser'] }"
                />
                <small class="p-error" v-if="errorsAdd['plafondUser']">
                  {{ errorsAdd["plafondUser"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Email </label>
                <InputText
                  v-model="clientToAdd.email"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['email'] }"
                />
                <small class="p-error" v-if="errorsAdd['email']">
                  {{ errorsAdd["email"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Mot de passe </label>
                <InputText
                  v-model="clientToAdd.password"
                  type="password"
                  :class="{ 'is-invalid': errorsAdd['password'] }"
                />
                <small class="p-error" v-if="errorsAdd['password']">
                  {{ errorsAdd["password"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Confirmation</label>
                <InputText
                  v-model="confirmpassword"
                  type="password"
                  :class="{ 'is-invalid': errorsAdd['confirmpassword'] }"
                />
                <small class="p-error" v-if="errorsAdd['confirmpassword']">
                  {{ errorsAdd["confirmpassword"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="Ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfEdit"
          :style="{ width: '900px' }"
          header="Modifier"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Non </label>

                <InputText
                  v-model="clientToUpdate.nom"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['nom'] }"
                />
                <small class="p-error" v-if="errorsUpdate['nom']">
                  {{ errorsUpdate["nom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Prénom </label>
                <InputText
                  v-model="clientToUpdate.prenom"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['prenom'] }"
                />
                <small class="p-error" v-if="errorsUpdate['prenom']">
                  {{ errorsUpdate["prenom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Téléphone </label>
                <InputText
                  v-model="clientToUpdate.tele"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['tele'] }"
                />
                <small class="p-error" v-if="errorsUpdate['tele']">
                  {{ errorsUpdate["tele"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="zip">GSM</label>
                <InputText
                  v-model="clientToUpdate.gsm"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['gsm'] }"
                />
                <small class="p-error" v-if="errorsUpdate['gsm']">
                  {{ errorsUpdate["gsm"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Role </label>
                <Dropdown
                  :class="{ 'is-invalid': errorsUpdate['role_id'] }"
                  v-model="role_id"
                  :options="roles"
                  optionLabel="name"
                  placeholder="sélectionner Role"
                  :filter="true"
                ></Dropdown>
                <small class="p-error" v-if="errorsUpdate['role_id']">
                  {{ errorsUpdate["role_id"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="city">Plafond </label>
                <InputNumber
                  v-model="clientToUpdate.plafondUser"
                  mode="currency"
                  currency="MAD"
                  :class="{ 'is-invalid': errorsUpdate['plafondUser'] }"
                />
                <small class="p-error" v-if="errorsUpdate['plafondUser']">
                  {{ errorsUpdate["plafondUser"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Email </label>
                <InputText
                  v-model="clientToUpdate.email"
                  type="text"
                  :class="{ 'is-invalid': errorsUpdate['email'] }"
                />
                <small class="p-error" v-if="errorsUpdate['email']">
                  {{ errorsUpdate["email"] }}
                </small>
              </div>

              <div class="field col-12 md:col-4">
                <label for="city">Mot de passe </label>
                <InputText
                  v-model="clientToUpdate.password"
                  type="password"
                  :class="{ 'is-invalid': errorsUpdate['password'] }"
                />
                <small class="p-error" v-if="errorsUpdate['password']">
                  {{ errorsUpdate["password"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Confirmation</label>
                <InputText
                  v-model="confirmpassword"
                  type="password"
                  :class="{ 'is-invalid': errorsUpdate['confirmpassword'] }"
                />
                <small class="p-error" v-if="errorsUpdate['confirmpassword']">
                  {{ errorsUpdate["confirmpassword"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfEdit = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormUpdate"
            />
          </template>
        </Dialog>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      clients: [],
      filters1: null,
      loading1: true,
      display: false,
      dialogOfShow: false,
      clientToShow: {},
      dialogOfEdit: false,
      clientToUpdate: {
        id: 0,
        role_id: 0,
        prenom: "",
        nom: "",
        tele: "",
        gsm: "",
        email: "",
        password: "",
        plafondUser: null,
      },
      roles: [],
      errorsUpdate: [],
      dialogOfAdd: false,
      clientToAdd: {
        role_id: 0,
        prenom: "",
        nom: "",
        tele: "",
        gsm: "",
        email: "",
        password: "",
        plafondUser: null,
      },
      confirmpassword: "",
      errorsAdd: [],
      role_id: null,
      dialogOfUpdateUser: false,
      permissionsValue: [],
      allPermissions: [],
      id: null,
    };
  },
  mounted() {
    console.log(localStorage.permissionNames);
    this.Permissions.update = localStorage.permissionNames.includes(
      "Modifier utilisateur"
    );
    this.Permissions.delete = localStorage.permissionNames.includes(
      "Supprimer utilisateur"
    );
    this.Permissions.add = localStorage.permissionNames.includes(
      "Ajouter utilisateur"
    );
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste utilisateur");
    if (this.Permissions.show) {
      this.showAllclients();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    this.initFilters1();
  },
  methods: {
    updateRole() {
      axios
        .post("/assignPermissionUser/" + this.id, {
          nameRole: this.name,
          PermissionIds: this.permissionsValue,
        })
        .then((response) => {
          this.dialogOfUpdateUser = false;
          console.log("response", response);
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    checkFormupP() {
      let checked = true;
      this.errorsAdd = [];

      if (this.permissionsValue.length == 0) {
        this.errorsAdd["permissions"] = "Permision est obligatoire.";
        checked = false;
      }
      if (checked) {
        this.updateRole();
      }
    },
    ititform() {
      this.id = null;
      this.permissionsValue = [];
      this.errorsAdd = [];
    },
    updatePermission(data) {
      this.ititform();
      axios
        .get("formAassignPermissionUser/" + data.id)
        .then((response) => {
          this.allPermissions = response.data.permisions;

          response.data.permissionUser.forEach((element) =>
            this.permissionsValue.push(element.id)
          );
          this.id = data.id;
          this.dialogOfUpdateUser = true;
          console.log(response);
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    deleteclient(id) {
      console.log(id);
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("deleteUser/" + id)
            .then((response) => {
              console.log(response);
              var index = this.clients
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.clients.splice(index, 1);
              console.log(this.clients);
              this.$swal({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    updateClientserve() {
      console.log(this.clientToUpdate);
      axios
        .post("modifierUser/" + this.clientToUpdate.id, this.clientToUpdate)
        .then((res) => {
          console.log("ret", res);
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    initformupdate() {
      this.clientToUpdate.id = 0;
      this.clientToUpdate.role_id = 0;
      this.clientToUpdate.prenom = "";
      this.clientToUpdate.nom = "";
      this.clientToUpdate.tele = "";
      this.clientToUpdate.gsm = "";
      this.clientToUpdate.email = "";
      this.clientToUpdate.password = "";
      this.confirmpassword = "";
      this.role_id = null;
      this.clientToUpdate.plafondUser = null;
    },
    initformadd() {
      this.confirmpassword = "";
      this.clientToAdd.role_id = 0;
      this.clientToAdd.prenom = "";
      this.clientToAdd.nom = "";
      this.clientToAdd.tele = "";
      this.clientToAdd.gsm = "";
      this.clientToAdd.email = "";
      this.clientToAdd.password = "";
      this.role_id = null;
      this.clientToAdd.plafondUser = null;
    },
    showAllclients() {
      axios
        .get("showUsers")
        .then((response) => {
          console.log(response);
          this.clients = response.data.users;
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addClient() {
      axios
        .post("registerUser", this.clientToAdd)
        .then((response) => {
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          console.log(response);
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showAddCLient() {
      this.initformadd();
      this.errorsAdd = [];
      axios
        .get("getAllRoles")
        .then((response) => {
          this.roles = response.data.roles;
          this.dialogOfAdd = true;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];
      if (this.role_id) {
        this.clientToAdd.role_id = this.role_id.id;
      }
      if (this.clientToAdd.nom == "") {
        this.errorsAdd["nom"] = "le champ Nom est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.plafondUser == "") {
        this.clientToAdd.plafondUser = null;
      }
      if (this.clientToAdd.prenom == "") {
        this.errorsAdd["prenom"] = "le champ Prénom est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.tele == "") {
        this.errorsAdd["tele"] = "le champ Téléphone est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.gsm == "") {
        this.errorsAdd["gsm"] = "le champ GSM est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.role_id == 0) {
        this.errorsAdd["role_id"] = "le champ role est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.email == "") {
        this.errorsAdd["email"] = "le champ Email est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.password == "") {
        this.errorsAdd["password"] = "le champ mot de passe est obligatoire.";
        checked = false;
      } else if (this.clientToAdd.password.length < 6) {
        this.errorsAdd["password"] =
          "le champ mot de passe doit comporter 6 caractères au minimum.";
        checked = false;
      } else if (this.confirmpassword == "") {
        this.errorsAdd["confirmpassword"] =
          "merci de confirmer votre mot de passe.";
        checked = false;
      } else if (this.confirmpassword != this.clientToAdd.password) {
        this.errorsAdd["confirmpassword"] = "confirmation erroné.";
        checked = false;
      }

      if (checked) {
        console.log(this.errorsAdd);
        this.addClient();
      }
    },
    checkFormUpdate() {
      let checked = true;
      this.errorsUpdate = [];
      if (this.role_id) {
        this.clientToUpdate.role_id = this.role_id.id;
      }
      if (this.clientToUpdate.plafondUser == "") {
        this.clientToUpdate.plafondUser = null;
      }
      if (this.clientToUpdate.nom == "") {
        this.errorsUpdate["nom"] = "le champ Nom est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.prenom == "") {
        this.errorsUpdate["prenom"] = "le champ Prénom est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.tele == "") {
        this.errorsUpdate["tele"] = "le champ Téléphone est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.gsm == "") {
        this.errorsUpdate["gsm"] = "le champ GSM est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.role_id == 0) {
        this.errorsUpdate["role_id"] = "le champ role est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.email == "") {
        this.errorsUpdate["email"] = "le champ Email est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.password != "") {
        if (this.clientToUpdate.password.length < 6) {
          this.errorsUpdate["password"] =
            "le champ mot de passe doit comporter 6 caractères au minimum.";
          checked = false;
        } else if (this.confirmpassword == "") {
          this.errorsUpdate["confirmpassword"] =
            "merci de confirmer votre mot de passe.";
          checked = false;
        } else if (this.confirmpassword != this.clientToUpdate.password) {
          this.errorsUpdate["confirmpassword"] = "confirmation erroné.";
          checked = false;
        }
      }

      if (checked) {
        this.updateClientserve();
      }
    },
    updateClient(id) {
      this.initformupdate();
      this.errorsUpdate = [];
      const index = this.clients.findIndex((x) => x.id == id);
      // this.clientToUpdate=this.clients[index];
      this.clientToUpdate.id = this.clients[index].id;
      this.clientToUpdate.role_id = this.clients[index].role_id;
      this.clientToUpdate.prenom = this.clients[index].prenom;
      this.clientToUpdate.nom = this.clients[index].nom;
      this.clientToUpdate.tele = this.clients[index].tele;
      this.clientToUpdate.gsm = this.clients[index].gsm;
      this.clientToUpdate.email = this.clients[index].email;
      this.clientToUpdate.plafondUser = this.clients[index].plafondUser;
      this.clientToUpdate.password = "";
      axios
        .get("getAllRoles")
        .then((response) => {
          this.roles = response.data.roles;
          const index = this.roles.findIndex(
            (x) => x.id === this.clientToUpdate.role_id
          );
          this.role_id = this.roles[index];
          console.log(this.roles);
          this.dialogOfEdit = true;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showclientById(client) {
      this.clientToShow = client;
      this.dialogOfShow = true;
    },

    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>